<template>
    <div class="card ">
        <div class="card-header bg-gray cabecera-secciondatospro">
            <h3 class="card-title"><i class="fas fa-align-left mr-2"></i>{{titulo}}</h3>
            <div class="card-tools">
                <button type="button" class="btn btn-success btn-xs editar-secciondatospro" id="boton_guardar_servicio">Guardar
                </button>
                <button type="button" class="btn btn-tool" data-card-widget="maximize">
                    <i class="fas fa-expand"></i>
                </button>
                <button type="button" class="btn btn-tool ver-secciondatospro"  v-if="haySlotForm" v-on:click="editar">
                    <i class="fas fa-edit"></i>
                </button>
                <button type="button" class="btn btn-tool editar-secciondatospro" v-if="haySlotSimple" v-on:click="ver">
                    <i class="fas fa-eye"></i>
                </button>
                <button type="button" class="btn btn-tool ampliar-secciondatospro" v-if="haySlotDetallado" v-on:click="ampliar">
                    <i class="fas fa-plus"></i>
                </button>
                <button type="button" class="btn btn-tool reducir-secciondatospro" v-if="haySlotForm" v-on:click="ver">
                    <i class="fas fa-minus"></i>
                </button>
            </div>
        </div>
        <div class="card-body body-secciondatospro">
            <div class="simple" v-if="mostrar==='simple'">
                <slot name="simple">   
                </slot>
            </div>
            <div class="detallado" v-if="mostrar === 'detallado'">
                <slot name="detallado">
                </slot>
            </div>
            <div class="form" v-if="mostrar === 'form'">
                <slot name="form">
                </slot>
            </div>
        </div>
    </div>
</template>
<script>
import $ from 'jquery'
export default ({
    props: ['titulo'],
    setup() {
        return;
    }, mounted() {
        this.ver();
    },
    computed: {

        haySlotSimple() {
            return !!this.$slots.simple;
            },
        haySlotDetallado() {
            return !!this.$slots.detallado;
        },
        haySlotForm() {
            return !!this.$slots.form;
        }
    },
    components: {
    },
    data() {
        return {
            mostrar : "simple",
        }
    },
    methods: {
        editar() {
            this.mostrar = "form";
            $(".editar-secciondatospro").show();
            $(".ver-secciondatospro").hide();
            $(".cabecera-secciondatospro").removeClass("bg-gray");
            $(".cabecera-secciondatospro").addClass("bg-black");
        },
        ver() {
            this.mostrar = "simple";
            $(".editar-secciondatospro").hide();
            $(".reducir-secciondatospro").hide();
            $(".ampliar-secciondatospro").show();
            $(".ver-secciondatospro").show();
            $(".cabecera-secciondatospro").removeClass("bg-black");
            $(".cabecera-secciondatospro").addClass("bg-gray");
        },
        ampliar() {
            this.mostrar = "detallado";
            $(".ampliar-secciondatospro").hide();
            $(".reducir-secciondatospro").show();
            $(".cabecera-secciondatospro").removeClass("bg-black");
            $(".cabecera-secciondatospro").addClass("bg-gray");
        }
    }
})
</script>